import { Link } from 'react-router-dom'
import { useLayoutEffect, useRef, useState } from 'react'
import { gsap } from 'gsap'
import {useNavigate} from 'react-router-dom';

const About = () => {

	const componentRef = useRef(null)

	const navigate = useNavigate()

	const [moreInfoContent, setMoreInfoContent] = useState('More information +')

	useLayoutEffect(() => {

		const component = componentRef.current

		let ctx = gsap.context(() => {
			
			const close = component.querySelector('.close')
			const closeTxt = component.querySelector('.close span')

			const transition = gsap.timeline({ paused: true })

			transition
				.fromTo(component, {width: 0}, { duration: .667, width: '100%', ease: 'power3.inOut'}, 0)
				.from(closeTxt, { duration: .333, opacity: 0, ease: 'none'}, .333)
			;

			transition.play()

			close.addEventListener('click', () => {
				transition.reverse()

				gsap.delayedCall(.5, navigate, ["/", "_"])
			})

			const moreInfo = component.querySelector('.moreInfo')
			const moreInfoTxt = component.querySelector('.moreInfoTxt')

			const moreInfoTl = gsap.timeline({ paused: true })

			moreInfoTl
				.from(moreInfoTxt, { duration: .667, height: 0, ease: 'power3.inOut'}, 0)
			;

			let moreInfoOpen = false

			moreInfo.addEventListener('click', () => {
				moreInfoOpen ? moreInfoTl.reverse() : moreInfoTl.play()
				
				moreInfoOpen ? setMoreInfoContent('More information +') : setMoreInfoContent('Less information -')

				moreInfoOpen = !moreInfoOpen
			})

		})

		return () => ctx.revert()

	}, [navigate])

	return (
		<div ref={componentRef} className='about b1 flex'>
			<div className='content flex-column'>
				<div className='text flex-column'>
					<p className='font-medium'>
						Ciao! My name is Linsey Dolleman. I am a Dutch graphic designer from Zaandam, the Netherlands. Since 2025 I am working as a freelancer after gathering a few years of experience working at different studios. 
						I graduated from the Graphic Design department at the Royal Academy of Arts in The Hague in 2019.
						Please feel free to contact me in English, Dutch or German.
					</p>
					<div className='moreInfoTxt m-t-56'>
						<p className='font-small'> Oktober 2024 — Present</p>
						<p className='font-default'> Digital and UI Designer at zukunvt, Bolzano</p>
						<br></br>
						<p className='font-small'> July 2023 — Present</p>
						<p className='font-default'> Digital and UI Designer at tt•consulting, Lana</p>
						<br></br>
						<p className='font-small'> August 2019 — June 2023</p>
						<p className='font-default'> Graphic Designer at Lungomare (formerly Lupo Burtscher), Bolzano</p>
						<br></br>
						<p className='font-small'> November 2019</p>
						<p className='font-default'> Nomination <i>Icarus Award</i>, ECP</p>
						<br></br>
						<p className='font-small'> September 2015 — July 2019</p>
						<p className='font-default'> Bachelor's degree (BA) in <i>Graphic Design</i> at Royal Academy of Art, The Hague</p>
						<br></br>
						<p className='font-small'> August — November 2018</p>
						<p className='font-default'> Internship at Studio Renate Boere, The Hague</p>
						<br></br>
						<p className='font-small'> April 2018</p>
						<p className='font-default'> Group Exhibition <i>Models for Humanity</i>, Amnesty International Nederland, Theater Amsterdam</p>
						<br></br>
						<p className='font-small'> September 2011 — July 2015</p>
						<p className='font-default'> MBO 4 degree in Graphic Design at Mediacollege, Amsterdam</p>
						<br></br>
						<p className='font-small'> January — July 2014</p>
						<p className='font-default'> Internship at JWT Amsterdam (department MAKE), Amsterdam</p>
						<br></br>
						<p className='font-small'> August 2013 — January 2014</p>
						<p className='font-default'> Internship at def. grafische vormgeving, Amsterdam</p>
					</div>
					<div className='moreInfo hoverElement m-t-40'>{moreInfoContent}</div>
				</div>
				<div className='contact p-t-80 font-medium m-t-auto flex space-between'>
					<div className='socials flex'>
						<a href='https://www.instagram.com/linseydolleman/' target='_blank' title='Instagram'>Instagram</a>
						<a href='https://www.linkedin.com/in/linseydolleman/' target='_blank' title='LinkedIn'>LinkedIn</a>
					</div>
					<a className='contactMail' href='mailto:linsey.dolleman(@)gmail.com' title='E-Mail'>linsey.dolleman(@)gmail.com</a>
				</div>
			</div>
			<div className='close hoverElement b2 flex-center'>
				<span className='font-small ttupper hoverElement'>close</span>
			</div>
		</div>
	)

}

export default About