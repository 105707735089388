import { useLayoutEffect, useRef } from 'react'
import { gsap } from 'gsap'
import Project from './project'

const Projects = () => {

	const componentRef = useRef(null)

	useLayoutEffect(() => {

		const component = componentRef.current

		let ctx = gsap.context(() => {

			const projects = component.querySelectorAll('.project')
			const projectNumbers = document.querySelectorAll('.number')

			const projectsHover = gsap.timeline({ paused: true })

			projectsHover
				.to(projectNumbers, {duration: 1, opacity: 0, ease: 'none', overwrite: 'auto'}, 0)
			;

			projects.forEach((project) => {
				project.addEventListener('mouseenter', () => {
					projectsHover.play(1)
				})

				project.addEventListener('mouseleave', () => {
					projectsHover.reverse()
				})
			})

		})

		return () => ctx.revert()

	}, [])

	const projects = [
		{
			projectName: 'Tea-LAB',
			filePath:'/assets/projects/tea.mp4',
			year: '2024',
			keywords : [
				'Package Design',
				'Illustration',
				'Concept Development'
			],
			createdAt: ''
		},
		{
			projectName: 'Letterpress Typeface',
			filePath:'/assets/projects/modular.gif',
			year: '2018',
			keywords : [
				'Modular',
				'Type Design'
			],
			createdAt: ''
		},
		{
			projectName: 'Shade Parade',
			filePath:'/assets/projects/homepage.mp4',
			year: '2024',
			keywords : [
				'Visual Identity',
				'Website Design',
				'Loading Animation'
			],
			createdAt: ''
		},
		{
			projectName: 'klimakultur',
			filePath:'/assets/projects/klimakultur.mp4',
			year: '2023',
			keywords : [
				'Visual Identity',
				'Website Design',
			],
			createdAt: 'Designed at Lungomare'
		},
		{
			projectName: 'Type Sandbox',
			filePath:'/assets/projects/sandbox.gif',
			year: '2024-ongoing',
			keywords : [
				'Type Design',
				'Experimentation',
			],
			createdAt: ''
		},
		{
			projectName: 'PingPong Event',
			filePath:'/assets/projects/pingpong.gif',
			year: '2024',
			keywords : [
				'Visual Identity',
				'Logo Design'
			],
			createdAt: ''
		},
		{
			projectName: 'The Human-Influence',
			filePath:'/assets/projects/collage.gif',
			year: '2025',
			keywords : [
				'Collage',
				'Editorial'
			],
			createdAt: ''
		}
	]

	return (
		<section ref={componentRef} className='projects'>
			<div className="wrap flex">
				{projects.map((project, index) => (
					<Project key={index} project={project} number={index} />
				))}
			</div>
		</section>
	)

}

export default Projects
